<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full lg:w-1/2">
        <div class="flex flex-row items-center inline py-4">
          <sa-icon-button
            iconClass="bx bx-left-arrow-alt"
            @click="$router.push({ name: 'clients.index' })"
            with-bg
          />
          <span class="mx-2 text-lg font-bold">Crear cliente</span>
        </div>
        <content-card>
          <client-form @onSaved="savedClient" />
        </content-card>
      </div>
    </div>
  </app-layout>
</template>
<script>
export default {
  name: "CreateIndex",
  components: {
    AppLayout: () => import("@/components/layouts/AppLayout.vue"),
    ContentCard: () => import("@/components/molecules/ContentCard.vue"),
    ClientForm: () => import("./ClientForm.vue"),
  },
  methods: {
    savedClient() {
      this.$router.push({ name: "clients.index" });
    },
  },
};
</script>
